import { STORAGE_TOKEN_KEYS as K } from 'helpers/constants';
import { getFromStorage } from 'services/storage';

const initialState = {
  isAuthenticated: false,
  profile: getFromStorage(K.USER),
  userId: null,
};

export const authenticate = (state, { payload }) => ({
  ...state,
  isAuthenticated: true,
  profile: payload,
});

export const logout = state => ({
  ...state,
  isAuthenticated: false,
  profile: null,
});

export const resendCode = state => ({ ...state });

export const set2faUserId = (state, { payload }) => ({ ...state, userId: payload.userId });

export default initialState;
