export const EXTERNAL = {
  INSTALLED: 'RapidPOS-external-app-installed',
  DATETIME: 'RapidPOS-external-app-install-date',
  INSTALLER: 'RapidPOS-external-app-installer',
};

// export const DOWNLOAD_URL = 'https://drive.google.com/uc?id=136vHZQKekGsGg_qY5VEaW8rrb049ezIc&export=download';
// export const DOWNLOAD_URL = "/files/RapidPOSAutoFormFill.zip"
// export const DOWNLOAD_URL =
//   'https://rapid4473devusstorage.blob.core.windows.net/enics/RapidPOSAutoFormFill.zip?sp=r&st=2021-09-21T19:13:55Z&se=2021-09-22T03:13:55Z&spr=https&sv=2020-08-04&sr=b&sig=miECs18hwDWUvDCA3bJ%2Fv8kcjVK3sTB3jixcekuSK6Q%3D';
// export const DOWNLOAD_URL = 'https://rapid4473devusstorage.blob.core.windows.net/enics/RapidPOSAutoFormFill.zip';
export const DOWNLOAD_URL =
  'https://chrome.google.com/webstore/detail/rapid-form-assistant/eeaoacpdkkkhjkbenadgkkiicmegfddo?hl=en';
export const AZURE_ACCOUNT = 'rapid4473devusstorage';
// export const AZURE_SAS = "?sv=2019-12-12&ss=bqtf&srt=sco&sp=rwdlacup&se=2021-09-02T02:22:27Z&sig=A%2BrimiC5rJs2GV%2BuM%2BPiG9b4EE%2FVaZfyyGQnaS9ftbM%3D&_=1630520736810";
export const AZURE_SAS =
  '?sv=2019-12-12&ss=bqtf&srt=sco&sp=rwdlacup&se=2021-09-02T02:08:38Z&sig=ZfDcrQnnxksWLG1t8cbz9Heq7hdd67%2FcZFzgGm4f1S0%3D&_=1630533719416';

export const AZURE_SHARE_NAME = 'enics-container';
export const AZURE_FILENAME = 'rapidpos-enics-setup.exe';

export const NICS_SITE = 'https://www.cjis.gov/';
export const EXTENSION_ID = 'jgnijpebpkehmmammhjglifkloacondi';
export const EXTENSION_FILE = 'images/get_started16.png';
export const INSTALLATION_ID = 'ExtensionCheck88';
