import update from 'immutability-helper';
import { statusesE4473 as E4473 } from 'helpers/constants';
import { zonedTimeToUtcString } from 'utils';

const today = zonedTimeToUtcString(new Date());

const initialState = {
  settings: {
    keyword: '',
    status: [E4473.STARTED, E4473.SIGNED],
    lstMaintDateFrom: today,
    lstMaintDateTo: today,
  },
  isUpdated: false,
  data: [],
  isLoading: false,
};

export const setLoading = state => ({
  ...state,
  isLoading: !state.isLoading,
});

export const setSettings = (state, { payload }) => ({
  ...state,
  settings: payload,
});

export const setData = (state, { payload }) => update(state, { isUpdated: { $set: true }, data: { $set: payload } });

export const setUpdated = (state, { payload }) => ({
  ...state,
  isUpdated: payload,
});

export default initialState;
