export const ROUTES = {
  AUTH_ROUTE: '/auth',
  LOGIN_ROUTE: '/auth/login',
  SIGNUP_ROUTE: '/auth/signup',
  LOGOUT_ROUTE: '/auth/logout',
  MAIN_ROUTE: '/main',
  DETAILS_ROUTE: '/main/details',
  SECTION_A_ROUTE_POSTFIX: 'sectionA',
  SECTION_B_ROUTE_POSTFIX: 'sectionB',
  SECTION_C_ROUTE_POSTFIX: 'sectionC',
  NICS_ROUTE_POSTFIX: 'nics',
  SECTION_D_ROUTE_POSTFIX: 'sectionD',
  SECTION_E_ROUTE_POSTFIX: 'sectionE',
  PRINT_ROUTE_POSTFIX: 'print',
  TERMINATE_ROUTE_POSTFIX: 'terminate',
  RECERTIFICATION: 'recertification',
};
