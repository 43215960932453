import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { ROUTES as R } from 'helpers/constants';

const PrivateRoute = ({ isAuthenticated, component: Component, redirectTo, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated) {
          return <Component {...props} />;
        }

        return (
          <Redirect
            to={{
              pathname: redirectTo,
              state: { from: rest.location },
            }}
          />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  redirectTo: PropTypes.string,
};

PrivateRoute.defaultProps = {
  redirectTo: R.AUTH_ROUTE,
};

export default PrivateRoute;
