export const statusesSectionB = {
  notReviewed: 'NOT REVIEWED',
  customerError: 'CUSTOMER ERROR',
  disallow: 'DISALLOW',
  approved: 'APPROVED',
};

export const terminateReasons = {
  completed: 'The transfer was COMPLETED',
  denied: 'The transfer was DENIED',
  cancelled: 'The transfer was CANCELLED',
  buyerCancelled: 'Buyer cancelled the transaction',
  sellerCancelled: 'Seller cancelled the transaction',
  proceedRecieved: 'No firearms were transferred –a PROCEED was received.',
  responseReceived: 'No firearms were transferred -no response was received.',
  sectionBNotCompleted: 'The customer has not completed Section B.',
};

export const statusesE4473 = {
  STARTED: 'STARTED',
  SIGNED: 'SIGNED',
  SUBMITTED: 'SUBMITTED',
  DELAYED: 'DELAYED',
  CANCELLED: 'CANCELLED',
  DENIED: 'DENIED',
  PROCEED: 'PROCEED',
  TERMINATED: 'TERMINATED',
  TRANSFER_COMPLETE: 'TRANSFER COMPLETE',
  RECERTIFY: 'RECERTIFY',
  DENIED_COMPLETE: 'DENIED COMPLETE',
  CANCELLED_COMPLETE: 'CANCELLED COMPLETE',
  IS_UPLOADED: 'IS UPLOADED',
  IS_MANUALLY_UPLOADED: 'MANUAL UPLOAD',
};

export const fieldDisplayedNamesMap = {
  '26A.C26A_ID_NO': 'Number on Identification',
  '26A.C26A_ID_TYPE': 'Issuing Authority and Type of Identification',
  '26A.C26A_ID_DATE.C26A_ID_EXPIRATION_MONTH': 'Expiration Date: Month',
  '26A.C26A_ID_DATE.C26A_ID_EXPIRATION_DAY': 'Expiration Date: Day',
  '26A.C26A_ID_DATE.C26A_ID_EXPIRATION_YEAR': 'Expiration Date: Year',
};

// export const ATF_TYPES = {
//   Any_Other_Weapon: 'Other',
//   Derringer: 'Other',
//   Destructive_Device: 'Other',
//   Frame: 'Other',
//   Machinegun: 'Other',
//   Pistol: 'Handgun',
//   Pistol_Grip_Firearm: 'Handgun',
//   Receiver: 'Other',
//   Revolver: 'Handgun',
//   Rifle: 'Long Gun',
//   Short_Barreled_Rifle: 'Long Gun',
//   Short_Barreled_Shotgun: 'Long Gun',
//   Shotgun: 'Long Gun',
//   Silencer: 'Other',
//   Weapon_Made_From_a_Rifle: 'Long Gun',
//   Weapon_Made_From_a_Shotgun: 'Long Gun',
// };
