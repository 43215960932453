import { createSlice } from '@reduxjs/toolkit';

import initialState, * as handlers from './handlers';

const main = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setLoading: handlers.setLoading,
    setData: handlers.setData,
    setSettings: handlers.setSettings,
    setUpdated: handlers.setUpdated,
  },
});

export const { actions } = main;

export default main.reducer;
