export const signaturePositions = {
  buyer: {
    page: 1,
    x: 170,
    y: 505,
  },
  recertify: {
    page: 2,
    x: 170,
    y: 630,
  },
  seller: {
    page: 2,
    x: 255,
    y: 365,
  },
};

export const signaturePositions042023 = {
  buyer: {
    page: 1,
    x: 170,
    y: 450,
  },
  recertify: {
    page: 2,
    x: 170,
    y: 485,
  },
  seller: {
    page: 2,
    x: 255,
    y: 242,
  },
};

export const signaturePositions202308 = {
  buyer: {
    page: 1,
    x: 170,
    y: 435,
  },
  recertify: {
    page: 2,
    x: 170,
    y: 485,
  },
  seller: {
    page: 2,
    x: 255,
    y: 242,
  },
};

export const documentFieldsOptions = {
  checkBoxMark: {
    x: 5,
    y: 5,
    size: 5,
    thickness: 1,
  },
};

export const signatureSizes = {
  buyer: {
    height: 28,
    width: 330,
  },
  recertify: {
    height: 28,
    width: 330,
  },
  seller: {
    height: 20,
    width: 200,
  },
};
