export const PASSWORD = 'password';
export const EMAIL = 'email';
export const FIRST_NAME = 'first name';
export const LAST_NAME = 'last name';
export const COMPANY = 'company';
export const PHONE_NUMBER = 'phone number';
export const SPECIALIZAION = 'specialization';
export const POSITION = 'position';
export const COUNTRY = 'country';
export const SUBSCRIPTION_TYPE = 'subscription type';
export const PROJECT_NAME = 'project name';
export const ASC = 'asc';
export const DESC = 'desc';

export const INVALID_EMAIL = 'Enter a valid email';
export const PASSWORD_SHOULD_CONTAIN =
  'Use 8 or more characters with a mix of letters (both lowercase and uppercase), numbers and special characters (!£$%^&*?@#._, -+=)';
export const ONLY_LATIN = 'Please use only letters (a-z), numbers and special characters (!£$%^&*?@#._, -+=)';
export const NO_SPACES = 'Please do not use spaces';
export const NOT_MATCH = 'Passwords do not match';
export const NAME_RESTRICTION = 'Please use letters (a-z), spaces and "-"';

export const TOKEN_EXPIRED_NOTIFICATION = 'You were logged out due to inactivity. Please sign in again.';
export const INTERNAL_ERROR_NOTIFICATION = 'Something went wrong.';

export const createRequiredText = text => `Enter ${text}`;
export const createLessThanText = num => `Use more than ${num} symbols`;
export const createShouldBeMoreThan = num => `Password should be ${num} or more characters`;
export const createMoreThanText = num => `Use less than ${num} symbols`;

export const createCannotEvaluateText = imos => `IMO(s) cannot be evaluated at the moment: ${imos}.`;

export const C27C_INIT_PROCEED = 'C27C_INIT_PROCEED';
export const C27C_INIT_DENIED = 'C27C_INIT_DENIED';
export const C27C_INIT_CANCELLED = 'C27C_INIT_CANCELLED';
export const C27C_INIT_DELAYED = 'C27C_INIT_DELAYED';
export const C28_NFA = '27C.C28_NFA';
export const C29_PERMIT = '27C.C29_PERMIT';

export const Y = 'Y';
export const N = 'N';
export const U = 'U';

export const AT_LEAST_ONE_ERROR = 'Must check at least one. May check more than one.';
export const RECORDS_THRESHOLD = 500;

export const REVIEW_SECTION_C = 'Review Section C. It does not yet indicate it is valid to proceed';
export const RECERTIFICATION_DATE_OUTDATED = 'Section D’s recertification date (field 31) is not equal to today’s date';
export const BUYER_SIGNATURE_OUTDATED =
  'The customer must complete Section D before you may click this button. Please click the Section D button to obtain a chit for your customer';

export const RECERTIFICATION_NOT_COMPLETED = 'You may not sign Section E until the customer completes Section D';
export const RECERTIFICATION_NOT_REQUIRED = 'Recertification is not required';
export const SECTION_C_NOT_MARKED_AS_FOLLOWS =
  'You may not complete Section E because Section C is not yet marked proceed, cancelled, or denied';
export const SECTION_D_NOT_RECRTIFIED =
  'You may not complete Section E because the customer has not recertified and signed Section D';
export const SECTION_D_RECRTIFIED_NOT_TODAY = 'You may not complete Section E because Section D was not signed today';

export const RESPONSE_DENIED = 'You may not transfer the firearm today because 27D indicates the transfer was denied';
export const RESPONSE_CANCELLED =
  'You may not transfer the firearm today because 27D indicates the transfer was cancelled';
export const LATER_RESPONSE_DENIED = 'You may not transfer the firearm because 27C indicates the transfer is Denied ';
export const LATER_RESPONSE_CANCELLED =
  'You may not transfer the firearm today because 27C indicates the transfer was cancelled';
export const DELAYED_RESPONSE_NOT_ENTERED =
  'You may not the firearm transfer today because 27C indicates the transfer is delayed and a response has not been entered into 27D';
export const TRANSFERED_DATE_OUTDATED =
  'You may not transfer the firearm today because the date in 27C has not been reached';
export const TRANSFER_COMPLETED = 'You may not edit the firearm transaction as firearm transfer has been completed';
export const NOT_PROCEED_STATUS =
  'Customer may not complete Section D recertification because you did not mark “Proceed” in Section C 27.c or 27.d';
export const CERTIFIED_TODAY = 'Customer completed Section B today and does not need to recertify';
export const RECERTIFIED_TODAY =
  'Customer completed Section D recertification today and does not need to recertify again';
export const NOT_SIGNED_STATUS = 'Customer has not signed Section B, Recertify is not allowed.';
