import jwt from 'jsonwebtoken';

import { STORAGE_TOKEN_KEYS as K } from 'helpers/constants';

export const setInStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value));

export const getFromStorage = key => {
  const value = localStorage.getItem(key);
  try {
    return JSON.parse(value);
  } catch (err) {
    return null;
  }
};

export const removeFromStorage = key => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(`Unable to remove ${key} from storage.`);
  }
};

export const clearStorage = () =>
  Object.values(K)
    .filter(x => x !== K.DEVICE_ID)
    .forEach(key => removeFromStorage(key));

export const saveTokens = (authenticationToken, refreshToken, refreshExpiresUtc, user) => {
  const { exp, twofa } = jwt.decode(authenticationToken);
  setInStorage(K.AUTH_TOKEN, authenticationToken);
  setInStorage(K.REFRESH_TOKEN, refreshToken);
  setInStorage(K.USER, user);
  setInStorage(K.AUTH_TOKEN_EXPIRATION, exp * 1000);
  setInStorage(K.REFRESH_TOKEN_EXPIRATION, refreshExpiresUtc);
  if (twofa) setInStorage(K.TWO_FA, twofa);
};

export const saveDeviceId = deviceId => {
  if (deviceId) {
    setInStorage(K.DEVICE_ID, deviceId);
  }
};
