const initialState = {
  data: {
    e4473: null,
    e4473Serials: null,
  },

  nics: {
    preNics: {
      // PRE_TRANS_PURPOSE: '',
      PRE_TRANS_PURPOSE_HG: false,
      PRE_TRANS_PURPOSE_LG: false,
      PRE_TRANS_PURPOSE_OTHER: false,
      PRE_TRANS_PURPOSE_PPHG: false,
      PRE_TRANS_PURPOSE_PPLG: false,
      PRE_TRANS_PURPOSE_PPOTHER: false,
      PRE_TRANS_PURPOSE_REDHG: false,
      PRE_TRANS_PURPOSE_REDLG: false,
      PRE_TRANS_PURPOSE_REDOTHER: false,
      PRE_MISC_NUMBER: '',
      PRE_MISC_TYPE: '',
      E4473_ID: null,
      DOC_GUID: '',
      STORE_NO: null,
      STORE_E4473_SEQ_NO: null,
      B9_FIRST_NAME: '',
      B9_LAST_NAME: '',
      B9_MIDDLE_NAME: '',
      B9_SUFFIX: '',
      B10_STATE: 'CA',
      B11_PLACE_OF_BIRTH_STATE: '',
      B11_PLACE_OF_BIRTH_FOREIGN_COUNTRY: null,
      B12_HEIGHT_FT: null,
      B12_HEIGHT_INCH: null,
      B13_WEIGHT_LBS: null,
      B14_MALE: '',
      B14_FEMALE: '',
      B14_NON_BINARY: '',
      B15_BIRTHDATE_DAT: '',
      B16_SSN: null,
      B17_UPIN: '',
      B18A_ETHNICITY_HISPANIC_LATINO: '',
      B18A_ETHNICITY_NOT_HISPANIC_LATINO: '',
      B18B_RACE_NATIVE: '',
      B18B_RACE_ASIAN: '',
      B18B_RACE_BLACK: '',
      B18B_RACE_WHITE: '',
      B18B_RACE_PACIFIC_ISLANDER: '',
      B19_COUNTRY_OF_CITIZENSHIP_USA: '',
      B19_COUNTRY_OF_CITIZENSHIP_OTHER: '',
      B19_COUNTRY_SPECIFY: null,
    },
    postNics: null,
  },

  isLoading: false,
  uploadResult: null,
  documentUploaded: false,
};

export const setLoading = state => ({
  ...state,
  isLoading: !state.isLoading,
});

export const setUploadResult = (state, { payload }) => {
  return {
    ...state,
    uploadResult: payload,
  };
};

export const setDocumentUploaded = (state, { payload }) => {
  return {
    ...state,
    documentUploaded: payload,
  };
};

export const setData = (state, { payload }) => ({
  ...state,
  data: payload,
  isLoading: false,
});

export const setPreNics = (state, { payload }) => ({
  ...state,
  nics: {
    ...payload,
    preNics: payload.preNics,
  },
  isLoading: false,
});

export const setPostNics = (state, { payload }) => ({
  ...state,
  nics: {
    ...state.nics,
    postNics: payload.postNics,
  },
  isLoading: false,
});

export const deleteSerial = (state, { id }) => ({
  ...state,
  data: {
    ...state.data,
    e4473Serials: [...state.data.e4473Serials.filter(item => item.SERIAL_SEQ_NO === id)],
  },
});

export const updatePawn = (state, { payload }) => ({
  ...state,
  data: {
    ...state.data,
    e4473Serials: [
      ...state.data.e4473Serials.map(item =>
        item.SERIAL_SEQ_NO === payload ? { ...item, PAWN_RDEMPTION_IND: !item.PAWN_RDEMPTION_IND } : item,
      ),
    ],
  },
});

export default initialState;
