import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useAsync } from 'react-use';

import { ROUTES as R } from 'helpers/constants';

import PrivateRoute from './PrivateRoute';

import { refreshAuthTokenIfNeeded } from '../http';

const Auth = lazy(() => import('pages/Auth'));
const AppRoute = lazy(() => import('./AppRouter'));

const AppRouter = () => {
  const userFromState = useSelector(state => state.auth.profile);
  const { value } = useAsync(async () => {
    const isTokenFresh = await refreshAuthTokenIfNeeded();
    return isTokenFresh;
  }, [userFromState]);

  const isAuthenticated = !!(userFromState && value) || false;

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path={R.AUTH_ROUTE} component={() => <Auth isAuthenticated={isAuthenticated} />} />
          <PrivateRoute path="/" component={AppRoute} isAuthenticated={isAuthenticated} redirectTo={R.LOGIN_ROUTE} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default AppRouter;
