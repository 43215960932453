// export const STORE_ID = 'd2de7daf-4389-4ce1-a4af-8e8deeca1981';

export function getApiVersion() {
  const pathname = window.location.href;

  if (pathname.indexOf('details') > 0) {
    const versionNo = pathname.split('/')[6];
    return versionNo == null || versionNo === '052020' ? '2.0' : versionNo;
  }

  return process.env.REACT_APP_API_VERSION;
}
