/* eslint-disable import/no-cycle */
import { toWords } from 'number-to-words';
import { format } from 'date-fns';
import { concatStrings, separators, formatBool } from 'helpers/strings';
import { spreadValues } from 'utils';
import { Y } from 'helpers/constants';

import { ROUTES } from './routes';

export const shortDateFormat = 'MM/dd/yyyy';

export const MENU_LINKS = [
  {
    to: ROUTES.SECTION_A_ROUTE_POSTFIX,
    title: 'Section A',
    permissions: [],
  },
  {
    to: ROUTES.SECTION_B_ROUTE_POSTFIX,
    title: 'Section B',
    permissions: [],
  },
  {
    to: ROUTES.PRINT_ROUTE_POSTFIX,
    title: 'Print',
    permissions: [],
  },
  {
    to: ROUTES.TERMITATE_ROUTE,
    title: 'Terminate',
    permissions: [],
  },
  {
    to: ROUTES.LOGOUT_ROUTE,
    title: 'Exit',
    permissions: [],
  },
];

export const customFieldsMap = {
  A6_ALPHA_NO_OF_FIREARMS: ({ A6_ALPHA_NO_OF_FIREARMS }) => ({
    // eslint-disable-next-line no-restricted-globals
    A6_ALPHA_NO_OF_FIREARMS: isNaN(A6_ALPHA_NO_OF_FIREARMS)
      ? A6_ALPHA_NO_OF_FIREARMS.toUpperCase()
      : toWords(A6_ALPHA_NO_OF_FIREARMS).toUpperCase(),
  }),
  B9_LAST_NAME: ({ B9_LAST_NAME, B9_SUFFIX }) => ({
    B9_LAST_NAME: concatStrings(separators.comma, B9_LAST_NAME, B9_SUFFIX),
  }),
  B11_PLACE_OF_BIRTH_CITY: ({ B11_PLACE_OF_BIRTH_STATE, B11_PLACE_OF_BIRTH_CITY }) => ({
    B11_PLACE_OF_BIRTH: concatStrings(separators.comma, B11_PLACE_OF_BIRTH_CITY, B11_PLACE_OF_BIRTH_STATE),
  }),
  B14_MALE: ({ B14_MALE }) => formatBool('B14_MALE', B14_MALE, false),
  B14_FEMALE: ({ B14_FEMALE }) => formatBool('B14_FEMALE', B14_FEMALE, false),
  B14_NON_BINARY: ({ B14_NON_BINARY }) => formatBool('B14_NON_BINARY', B14_NON_BINARY, false),
  B18A_ETHNICITY_HISPANIC_LATINO: ({ B18A_ETHNICITY_HISPANIC_LATINO }) =>
    formatBool('B18A_ETHNICITY_HISPANIC_LATINO', B18A_ETHNICITY_HISPANIC_LATINO, false),
  B18A_ETHNICITY_NOT_HISPANIC_LATINO: ({ B18A_ETHNICITY_NOT_HISPANIC_LATINO }) =>
    formatBool('B18A_ETHNICITY_NOT_HISPANIC_LATINO', B18A_ETHNICITY_NOT_HISPANIC_LATINO, false),
  B18B_RACE_NATIVE: ({ B18B_RACE_NATIVE }) => formatBool('B18B_RACE_NATIVE', B18B_RACE_NATIVE, false),
  B18B_RACE_ASIAN: ({ B18B_RACE_ASIAN }) => formatBool('B18B_RACE_ASIAN', B18B_RACE_ASIAN, false),
  B18B_RACE_BLACK: ({ B18B_RACE_BLACK }) => formatBool('B18B_RACE_BLACK', B18B_RACE_BLACK, false),
  B18B_RACE_WHITE: ({ B18B_RACE_WHITE }) => formatBool('B18B_RACE_WHITE', B18B_RACE_WHITE, false),
  B18B_RACE_PACIFIC_ISLANDER: ({ B18B_RACE_PACIFIC_ISLANDER }) =>
    formatBool('B18B_RACE_PACIFIC_ISLANDER', B18B_RACE_PACIFIC_ISLANDER, false),
  B19_COUNTRY_OF_CITIZENSHIP_USA: ({ B19_COUNTRY_OF_CITIZENSHIP_USA }) =>
    formatBool('B19_COUNTRY_OF_CITIZENSHIP_USA', B19_COUNTRY_OF_CITIZENSHIP_USA, false),
  B19_COUNTRY_OF_CITIZENSHIP_OTHER: ({ B19_COUNTRY_OF_CITIZENSHIP_OTHER }) =>
    formatBool('B19_COUNTRY_OF_CITIZENSHIP_OTHER', B19_COUNTRY_OF_CITIZENSHIP_OTHER, false),
  B21A_ACTUAL_BUYER: ({ B21A_ACTUAL_BUYER }) => formatBool('B21A_ACTUAL_BUYER', B21A_ACTUAL_BUYER),
  B21B_FELONY_INDICT: ({ B21B_FELONY_INDICT }) => formatBool('B21B_FELONY_INDICT', B21B_FELONY_INDICT),
  B21C_FELONY_CONVICT: ({ B21C_FELONY_CONVICT }) => formatBool('B21C_FELONY_CONVICT', B21C_FELONY_CONVICT),
  B21D_FUGITIVE: ({ B21D_FUGITIVE }) => formatBool('B21D_FUGITIVE', B21D_FUGITIVE),
  B21E_ADDICT: ({ B21E_ADDICT }) => formatBool('B21E_ADDICT', B21E_ADDICT),
  B21F_MENTAL: ({ B21F_MENTAL }) => formatBool('B21F_MENTAL', B21F_MENTAL),
  B21G_DISHONORABLE: ({ B21G_DISHONORABLE }) => formatBool('B21G_DISHONORABLE', B21G_DISHONORABLE),
  B21H_HARASS: ({ B21H_HARASS }) => formatBool('B21H_HARASS', B21H_HARASS),
  B21I_DOM_VIOLENCE: ({ B21I_DOM_VIOLENCE }) => formatBool('B21I_DOM_VIOLENCE', B21I_DOM_VIOLENCE),
  B21J_RENOUNCE: ({ B21J_RENOUNCE }) => formatBool('B21J_RENOUNCE', B21J_RENOUNCE),
  B21K_ALIEN: ({ B21K_ALIEN }) => formatBool('B21K_ALIEN', B21K_ALIEN),
  B21L1_NON_IMMIGRANT: ({ B21L1_NON_IMMIGRANT }) => formatBool('B21L1_NON_IMMIGRANT', B21L1_NON_IMMIGRANT),
  B21L2_NON_IMMIGRANT_EXCEPTION: ({ B21L2_NON_IMMIGRANT_EXCEPTION }) =>
    formatBool('B21L2_NON_IMMIGRANT_EXCEPTION', B21L2_NON_IMMIGRANT_EXCEPTION),
  C24_HANDGUN: ({ C24_HANDGUN }) => formatBool('C24_HANDGUN', C24_HANDGUN, false),
  C24_LONG_GUN: ({ C24_LONG_GUN }) => formatBool('C24_LONG_GUN', C24_LONG_GUN, false),
  C24_OTHER_GUN: ({ C24_OTHER_GUN }) => formatBool('C24_OTHER_GUN', C24_OTHER_GUN, false),
  C27C_INIT_PROCEED: ({ C27C_INIT_PROCEED }) => formatBool('C27C_INIT_PROCEED', C27C_INIT_PROCEED, false),
  C27C_INIT_DENIED: ({ C27C_INIT_DENIED }) => formatBool('C27C_INIT_DENIED', C27C_INIT_DENIED, false),
  C27C_INIT_CANCELLED: ({ C27C_INIT_CANCELLED }) => formatBool('C27C_INIT_CANCELLED', C27C_INIT_CANCELLED, false),
  C27C_INIT_DELAYED: ({ C27C_INIT_DELAYED }) => formatBool('C27C_INIT_DELAYED', C27C_INIT_DELAYED, false),
  C27D_PRE_XFER_PROCEED: ({ C27D_PRE_XFER_PROCEED }) =>
    formatBool('C27D_PRE_XFER_PROCEED', C27D_PRE_XFER_PROCEED, false),
  C27D_PRE_XFER_DENIED: ({ C27D_PRE_XFER_DENIED }) => formatBool('C27D_PRE_XFER_DENIED', C27D_PRE_XFER_DENIED, false),
  C27D_PRE_XFER_CANCELLED: ({ C27D_PRE_XFER_CANCELLED }) =>
    formatBool('C27D_PRE_XFER_CANCELLED', C27D_PRE_XFER_CANCELLED, false),
  C27D_PRE_XFER_OVERTURNED: ({ C27D_PRE_XFER_OVERTURNED }) =>
    formatBool('C27D_PRE_XFER_OVERTURNED', C27D_PRE_XFER_OVERTURNED, false),
  C27D_PRE_XFER_NO_RESPONSE: ({ C27D_PRE_XFER_NO_RESPONSE }) =>
    formatBool('C27D_PRE_XFER_NO_RESPONSE', C27D_PRE_XFER_NO_RESPONSE, false),
  C27E_AFTER_PROCEED: ({ C27E_AFTER_PROCEED }) => formatBool('C27E_AFTER_PROCEED', C27E_AFTER_PROCEED, false),
  C27E_AFTER_DENIED: ({ C27E_AFTER_DENIED }) => formatBool('C27E_AFTER_DENIED', C27E_AFTER_DENIED, false),
  C27E_AFTER_CANCELLED: ({ C27E_AFTER_CANCELLED }) => formatBool('C27E_AFTER_CANCELLED', C27E_AFTER_CANCELLED, false),
  C28_NFA: ({ C28_NFA }) => formatBool('C28_NFA', C28_NFA, false),
  C29_PERMIT: ({ C29_PERMIT }) => formatBool('C29_PERMIT', C29_PERMIT, false),
  C26C_PCS_EFFECTIVE_DAT: ({ C26C_PCS_EFFECTIVE_DAT }) => ({
    C26C_PCS_EFFECTIVE_DAT: C26C_PCS_EFFECTIVE_DAT && format(new Date(C26C_PCS_EFFECTIVE_DAT), shortDateFormat),
  }),
  C27C_INIT_ALLOW_TRANSFER_DAT: ({ C27C_INIT_ALLOW_TRANSFER_DAT }) => ({
    C27C_INIT_ALLOW_TRANSFER_DAT:
      C27C_INIT_ALLOW_TRANSFER_DAT && format(new Date(C27C_INIT_ALLOW_TRANSFER_DAT), shortDateFormat),
  }),
  C27D_PRE_XFER_PROCEED_DAT: ({ C27D_PRE_XFER_PROCEED_DAT }) => ({
    C27D_PRE_XFER_PROCEED_DAT:
      C27D_PRE_XFER_PROCEED_DAT && format(new Date(C27D_PRE_XFER_PROCEED_DAT), shortDateFormat),
  }),
  C27D_PRE_XFER_DENIED_DAT: ({ C27D_PRE_XFER_DENIED_DAT }) => ({
    C27D_PRE_XFER_DENIED_DAT: C27D_PRE_XFER_DENIED_DAT && format(new Date(C27D_PRE_XFER_DENIED_DAT), shortDateFormat),
  }),
  C27D_PRE_XFER_CANCELLED_DAT: ({ C27D_PRE_XFER_CANCELLED_DAT }) => ({
    C27D_PRE_XFER_CANCELLED_DAT:
      C27D_PRE_XFER_CANCELLED_DAT && format(new Date(C27D_PRE_XFER_CANCELLED_DAT), shortDateFormat),
  }),
  C27E_AFTER_RESPONSE_DAT: ({ C27E_AFTER_RESPONSE_DAT }) => ({
    C27E_AFTER_RESPONSE_DAT: C27E_AFTER_RESPONSE_DAT && format(new Date(C27E_AFTER_RESPONSE_DAT), shortDateFormat),
  }),
  C29_PERMIT_ISSUE_DAT: ({ C29_PERMIT_ISSUE_DAT }) => ({
    C29_PERMIT_ISSUE_DAT: C29_PERMIT_ISSUE_DAT && format(new Date(C29_PERMIT_ISSUE_DAT), shortDateFormat),
  }),
  C29_PERMIT_EXPIRATION_DAT: ({ C29_PERMIT_EXPIRATION_DAT }) => ({
    C29_PERMIT_EXPIRATION_DAT:
      C29_PERMIT_EXPIRATION_DAT && format(new Date(C29_PERMIT_EXPIRATION_DAT), shortDateFormat),
  }),
  E33_CORP_NAME: ({
    E33_CORP_NAME,
    E33_CORP_ADDRESS_1,
    E33_CORP_ADDRESS_2,
    E33_CORP_CITY,
    E33_CORP_STATE,
    E33_CORP_ZIP_CODE,
    E33_CORP_FFL_NO,
  }) => ({
    E33_CORP_NAME:
      `${E33_CORP_NAME ? `${E33_CORP_NAME}\n` : ''}` +
      `${E33_CORP_ADDRESS_1 ? `${E33_CORP_ADDRESS_1}\n` : ''}` +
      `${E33_CORP_ADDRESS_2 ? `${E33_CORP_ADDRESS_2}\n` : ''}` +
      `${
        E33_CORP_CITY || E33_CORP_STATE || E33_CORP_ZIP_CODE
          ? `${concatStrings(separators.comma, E33_CORP_CITY, E33_CORP_STATE, E33_CORP_ZIP_CODE)}\n`
          : ''
      }` +
      `${E33_CORP_FFL_NO ? `${E33_CORP_FFL_NO}` : ''}`,
  }),

  // 042023 updates
  B10_CITY_LIMITS: ({ B10_CITY_LIMITS }) => formatBool('B10_CITY_LIMITS', B10_CITY_LIMITS),

  B21B_INTEND_PURCHASE: ({ B21B_INTEND_PURCHASE }) => formatBool('B21B_INTEND_PURCHASE', B21B_INTEND_PURCHASE),
  B21C_INTEND_SELL: ({ B21C_INTEND_SELL }) => formatBool('B21C_INTEND_SELL', B21C_INTEND_SELL),
  B21D_FELONY_INDICT: ({ B21D_FELONY_INDICT }) => formatBool('B21D_FELONY_INDICT', B21D_FELONY_INDICT),
  B21E_FELONY_CONVICT: ({ B21E_FELONY_CONVICT }) => formatBool('B21E_FELONY_CONVICT', B21E_FELONY_CONVICT),
  B21F_FUGITIVE: ({ B21F_FUGITIVE }) => formatBool('B21F_FUGITIVE', B21F_FUGITIVE),
  B21G_ADDICT: ({ B21G_ADDICT }) => formatBool('B21G_ADDICT', B21G_ADDICT),
  B21H_MENTAL: ({ B21H_MENTAL }) => formatBool('B21H_MENTAL', B21H_MENTAL),
  B21I_DISHONORABLE: ({ B21I_DISHONORABLE }) => formatBool('B21I_DISHONORABLE', B21I_DISHONORABLE),
  B21J_HARASS: ({ B21J_HARASS }) => formatBool('B21J_HARASS', B21J_HARASS),
  B21K_DOM_VIOLENCE: ({ B21K_DOM_VIOLENCE }) => formatBool('B21K_DOM_VIOLENCE', B21K_DOM_VIOLENCE),
  B21L_RENOUNCE: ({ B21L_RENOUNCE }) => formatBool('B21L_RENOUNCE', B21L_RENOUNCE),
  B21M_ALIEN: ({ B21M_ALIEN }) => formatBool('B21M_ALIEN', B21M_ALIEN),
  B21N1_NON_IMMIGRANT: ({ B21N1_NON_IMMIGRANT }) => formatBool('B21N1_NON_IMMIGRANT', B21N1_NON_IMMIGRANT),
  B21N2_NON_IMMIGRANT_EXCEPTION: ({ B21N2_NON_IMMIGRANT_EXCEPTION }) =>
    formatBool('B21N2_NON_IMMIGRANT_EXCEPTION', B21N2_NON_IMMIGRANT_EXCEPTION),

  C25_COUNTY: ({ C25_COUNTY }) => ({ C25_COUNTY }),
  C27D_PRE_XFER_OVERTURNED_DAT: ({ C27D_PRE_XFER_OVERTURNED_DAT }) => ({
    C27D_PRE_XFER_OVERTURNED_DAT:
      C27D_PRE_XFER_OVERTURNED_DAT && format(new Date(C27D_PRE_XFER_OVERTURNED_DAT), shortDateFormat),
  }),
  C27D_PRE_XFER_ADDL_21: ({ C27D_PRE_XFER_ADDL_21 }) =>
    formatBool('C27D_PRE_XFER_ADDL_21', C27D_PRE_XFER_ADDL_21, false),
  C27D_PRE_XFER_ADDL_21_DATE_RECV: ({ C27D_PRE_XFER_ADDL_21_DATE_RECV }) => ({
    C27D_PRE_XFER_ADDL_21_DATE_RECV:
      C27D_PRE_XFER_ADDL_21_DATE_RECV && format(new Date(C27D_PRE_XFER_ADDL_21_DATE_RECV), shortDateFormat),
  }),
  C27D_PRE_XFER_ADDL_21_DATE_ALLOW: ({ C27D_PRE_XFER_ADDL_21_DATE_ALLOW }) => ({
    C27D_PRE_XFER_ADDL_21_DATE_ALLOW:
      C27D_PRE_XFER_ADDL_21_DATE_ALLOW && format(new Date(C27D_PRE_XFER_ADDL_21_DATE_ALLOW), shortDateFormat),
  }),
  C27D_PRE_XFER_ADDL_21_NO_RESPONSE: ({ C27D_PRE_XFER_ADDL_21_NO_RESPONSE }) =>
    formatBool('C27D_PRE_XFER_ADDL_21_NO_RESPONSE', C27D_PRE_XFER_ADDL_21_NO_RESPONSE, false),
};

export const sectionCCustomFieldMap = {
  ...customFieldsMap,
  C24_HANDGUN: ({ C24_HANDGUN }) => formatBool('24.C24_HANDGUN', C24_HANDGUN, false),
  C24_LONG_GUN: ({ C24_LONG_GUN }) => formatBool('24.C24_LONG_GUN', C24_LONG_GUN, false),
  C24_OTHER_GUN: ({ C24_OTHER_GUN }) => formatBool('24.C24_OTHER_GUN', C24_OTHER_GUN, false),
  C25_NAME_OF_FUNTION: ({ C25_NAME_OF_FUNTION }) => ({ '25.C25_NAME_OF_FUNTION': C25_NAME_OF_FUNTION }),
  C25_CITY_STATE: ({ C25_CITY_STATE }) => ({ '25.C25_CITY_STATE': C25_CITY_STATE }),
  C26A_ID_NO: ({ C26A_ID_NO }) => ({ '26A.C26A_ID_NO': C26A_ID_NO }),
  C26A_ID_TYPE: ({ C26A_ID_TYPE }) => ({ '26A.C26A_ID_TYPE': C26A_ID_TYPE }),
  C26A_ID_EXPIRATION_MONTH: ({ C26A_ID_EXPIRATION_MONTH }) => ({
    '26A.C26A_ID_DATE.C26A_ID_EXPIRATION_MONTH': `0${C26A_ID_EXPIRATION_MONTH}`.slice(
      `0${C26A_ID_EXPIRATION_MONTH}`.length - 2,
    ),
  }),
  C26A_ID_EXPIRATION_DAY: ({ C26A_ID_EXPIRATION_DAY }) => ({
    '26A.C26A_ID_DATE.C26A_ID_EXPIRATION_DAY': `0${C26A_ID_EXPIRATION_DAY}`.slice(
      `0${C26A_ID_EXPIRATION_DAY}`.length - 2,
    ),
  }),
  C26A_ID_EXPIRATION_YEAR: ({ C26A_ID_EXPIRATION_YEAR }) => ({
    '26A.C26A_ID_DATE.C26A_ID_EXPIRATION_YEAR': C26A_ID_EXPIRATION_YEAR,
  }),
  C26C_PCS_BASE: ({ C26C_PCS_BASE }) => ({ '26C.C26C_PCS_BASE': C26C_PCS_BASE }),
  C26C_PCS_EFFECTIVE_DAT: ({ C26C_PCS_EFFECTIVE_DAT }) => ({
    '26C.C26C_PCS_EFFECTIVE_DAT': C26C_PCS_EFFECTIVE_DAT && format(new Date(C26C_PCS_EFFECTIVE_DAT), shortDateFormat),
  }),
  C26C_PCS_ORDER_NO: ({ C26C_PCS_ORDER_NO }) => ({ '26C.C26C_PCS_ORDER_NO': C26C_PCS_ORDER_NO }),
  C27A_NICS_MONTH: ({ C27A_NICS_MONTH }) => ({
    '27C.27A.C27A_NICS_MONTH': `0${C27A_NICS_MONTH}`.slice(`0${C27A_NICS_MONTH}`.length - 2),
  }),
  C27A_NICS_DAY: ({ C27A_NICS_DAY }) => ({
    '27C.27A.C27A_NICS_DAY': `0${C27A_NICS_DAY}`.slice(`0${C27A_NICS_DAY}`.length - 2),
  }),
  C27A_NICS_YEAR: ({ C27A_NICS_YEAR }) => ({ '27C.27A.C27A_NICS_YEAR': C27A_NICS_YEAR }),
  C27C: e4473 => ({
    '27C.C27C': spreadValues(
      e4473,
      ['C27C_INIT_CANCELLED', 'C27C_INIT_DELAYED', 'C27C_INIT_DENIED', 'C27C_INIT_PROCEED'],
      [Y],
    ),
  }),
  C27C_INIT_ALLOW_TRANSFER_DAT: ({ C27C_INIT_ALLOW_TRANSFER_DAT }) => ({
    '27C.C27C_INIT_ALLOW_TRANSFER_DAT':
      C27C_INIT_ALLOW_TRANSFER_DAT && format(new Date(C27C_INIT_ALLOW_TRANSFER_DAT), shortDateFormat),
  }),
  C27D_PRE_XFER_PROCEED: ({ C27D_PRE_XFER_PROCEED }) =>
    formatBool('27C.D27.C27D_PRE_XFER_PROCEED', C27D_PRE_XFER_PROCEED, false),
  C27D_PRE_XFER_PROCEED_DAT: ({ C27D_PRE_XFER_PROCEED_DAT }) => ({
    '27C.D27.C27D_PRE_XFER_PROCEED_DAT':
      C27D_PRE_XFER_PROCEED_DAT && format(new Date(C27D_PRE_XFER_PROCEED_DAT), shortDateFormat),
  }),
  C27D_PRE_XFER_DENIED: ({ C27D_PRE_XFER_DENIED }) =>
    formatBool('27C.D27.C27D_PRE_XFER_DENIED', C27D_PRE_XFER_DENIED, false),
  C27D_PRE_XFER_DENIED_DAT: ({ C27D_PRE_XFER_DENIED_DAT }) => ({
    '27C.D27.C27D_PRE_XFER_DENIED_DAT':
      C27D_PRE_XFER_DENIED_DAT && format(new Date(C27D_PRE_XFER_DENIED_DAT), shortDateFormat),
  }),
  C27D_PRE_XFER_CANCELLED: ({ C27D_PRE_XFER_CANCELLED }) =>
    formatBool('27C.D27.C27D_PRE_XFER_CANCELLED', C27D_PRE_XFER_CANCELLED, false),
  C27D_PRE_XFER_CANCELLED_DAT: ({ C27D_PRE_XFER_CANCELLED_DAT }) => ({
    '27C.D27.C27D_PRE_XFER_CANCELLED_DAT':
      C27D_PRE_XFER_CANCELLED_DAT && format(new Date(C27D_PRE_XFER_CANCELLED_DAT), shortDateFormat),
  }),
  C27D_PRE_XFER_NO_RESPONSE: ({ C27D_PRE_XFER_NO_RESPONSE }) =>
    formatBool('27C.D27.C27D_PRE_XFER_NO_RESPONSE', C27D_PRE_XFER_NO_RESPONSE, false),
  C27D_PRE_XFER_OVERTURNED: ({ C27D_PRE_XFER_OVERTURNED }) =>
    formatBool('27C.D27.C27D_PRE_XFER_OVERTURNED', C27D_PRE_XFER_OVERTURNED, false),
  C27E_AFTER_RESPONSE_DAT: ({ C27E_AFTER_RESPONSE_DAT }) => ({
    '27E.C27E_AFTER_RESPONSE_DAT':
      C27E_AFTER_RESPONSE_DAT && format(new Date(C27E_AFTER_RESPONSE_DAT), shortDateFormat),
  }),
  C27E_AFTER_PROCEED: ({ C27E_AFTER_PROCEED }) => formatBool('27E.C27E_AFTER_PROCEED', C27E_AFTER_PROCEED, false),
  C27E_AFTER_DENIED: ({ C27E_AFTER_DENIED }) => formatBool('27E.C27E_AFTER_DENIED', C27E_AFTER_DENIED, false),
  C27E_AFTER_CANCELLED: ({ C27E_AFTER_CANCELLED }) =>
    formatBool('27E.C27E_AFTER_CANCELLED', C27E_AFTER_CANCELLED, false),
  C27F_NICS_EXAM_NAME: ({ C27F_NICS_EXAM_NAME }) => ({ '27F.C27F_NICS_EXAM_NAME': C27F_NICS_EXAM_NAME }),
  C27F_NICS_EXAM_NUMBER: ({ C27F_NICS_EXAM_NUMBER }) => ({ '27F.C27F_NICS_EXAM_NUMBER': C27F_NICS_EXAM_NUMBER }),
  C28_NFA: ({ C28_NFA }) => formatBool('27C.C28_NFA', C28_NFA, false),
  C29_PERMIT: ({ C29_PERMIT }) => formatBool('27C.C29_PERMIT', C29_PERMIT, false),
  C29_PERMIT_STATE_TYPE: ({ C29_PERMIT_STATE_TYPE }) => ({ '27C.C29_PERMIT_STATE_TYPE': C29_PERMIT_STATE_TYPE }),
  C29_PERMIT_ISSUE_DAT: ({ C29_PERMIT_ISSUE_DAT }) => ({
    '27C.C29_PERMIT_ISSUE_DAT': C29_PERMIT_ISSUE_DAT && format(new Date(C29_PERMIT_ISSUE_DAT), shortDateFormat),
  }),
  C29_PERMIT_EXPIRATION_DAT: ({ C29_PERMIT_EXPIRATION_DAT }) => ({
    '27C.C29_PERMIT_EXPIRATION_DAT':
      C29_PERMIT_EXPIRATION_DAT && format(new Date(C29_PERMIT_EXPIRATION_DAT), shortDateFormat),
  }),
  C29_PERMIT_NO: ({ C29_PERMIT_NO }) => ({ '27C.C29_PERMIT_NO': C29_PERMIT_NO }),

  // 042023 updates
  C25_COUNTY: ({ C25_COUNTY }) => ({ '25.C25_COUNTY': C25_COUNTY }),
  C25_ADDRESS: ({ C25_ADDRESS }) => ({ '25.C25_ADDRESS': C25_ADDRESS }),
  C27D_PRE_XFER_OVERTURNED_DAT: ({ C27D_PRE_XFER_OVERTURNED_DAT }) => ({
    '27C.D27.C27D_PRE_XFER_OVERTURNED_DAT':
      C27D_PRE_XFER_OVERTURNED_DAT && format(new Date(C27D_PRE_XFER_OVERTURNED_DAT), shortDateFormat),
  }),
  C27D_PRE_XFER_ADDL_21: ({ C27D_PRE_XFER_ADDL_21 }) =>
    formatBool('27C.D27.C27D_PRE_XFER_ADDL_21', C27D_PRE_XFER_ADDL_21, false),
  C27D_PRE_XFER_ADDL_21_DATE_RECV: ({ C27D_PRE_XFER_ADDL_21_DATE_RECV }) => ({
    '27C.D27.C27D_PRE_XFER_ADDL_21_DATE_RECV':
      C27D_PRE_XFER_ADDL_21_DATE_RECV && format(new Date(C27D_PRE_XFER_ADDL_21_DATE_RECV), shortDateFormat),
  }),
  C27D_PRE_XFER_ADDL_21_DATE_ALLOW: ({ C27D_PRE_XFER_ADDL_21_DATE_ALLOW }) => ({
    '27C.D27.C27D_PRE_XFER_ADDL_21_DATE_ALLOW':
      C27D_PRE_XFER_ADDL_21_DATE_ALLOW && format(new Date(C27D_PRE_XFER_ADDL_21_DATE_ALLOW), shortDateFormat),
  }),
  C27D_PRE_XFER_ADDL_21_NO_RESPONSE: ({ C27D_PRE_XFER_ADDL_21_NO_RESPONSE }) =>
    formatBool('27C.D27.C27D_PRE_XFER_ADDL_21_NO_RESPONSE', C27D_PRE_XFER_ADDL_21_NO_RESPONSE, false),
};

export const documentTypes = {
  main: '4473upd_6pt.26.04.21.pdf',
  main_v042023: 'e4473_042023_pdf_form.pdf',
  main_v202308: 'f_5300.9_4473_v202308_pdf_doc.pdf',
  continuation: 'continuationSheet_6pt.26.04.21.pdf',
};

export const pdfFiledLineLength = {
  A5_CALIBER_GAUGE: 11,
  A1_MANUFACTURER_IMPORTER: 54,
  A4_ATF_TYPE: 20,
};

export const signatureFieldsMap = {
  buyer: {
    field: 'B22_BUYER_SIGNATURE',
  },
  recertify: {
    field: 'D30_RECERTIFY_SIG',
  },
  seller: {
    field: 'E35_SELLER_SIGNATURE',
  },
};
