import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import auth from './auth';
import main from './main';
import details from './main/details';

const rootReducer = combineReducers({
  auth,
  main,
  details,
});

const store = createStore(rootReducer, composeWithDevTools());

export default store;
