import { createSlice } from '@reduxjs/toolkit';

import initialState, * as handlers from './handlers';

const details = createSlice({
  name: 'details',
  initialState,
  reducers: {
    setUploadResult: handlers.setUploadResult,
    setDocumentUploaded: handlers.setDocumentUploaded,
    setLoading: handlers.setLoading,
    setData: handlers.setData,
    deleteSerial: handlers.deleteSerial,
    updatePawn: handlers.updatePawn,
    setPreNics: handlers.setPreNics,
    setPostNics: handlers.setPostNics,
  },
});

export const { actions } = details;

export * from './formatters';

export default details.reducer;
