import { format, addMinutes, parseISO } from 'date-fns';
import {
  INTERNAL_ERROR_NOTIFICATION,
  AZURE_ACCOUNT,
  AZURE_SAS,
  AZURE_SHARE_NAME,
  AZURE_FILENAME,
} from 'helpers/constants';
import { ShareServiceClient } from '@azure/storage-file-share';

const account = AZURE_ACCOUNT;
const sas = AZURE_SAS;
const shareName = AZURE_SHARE_NAME;
const filename = AZURE_FILENAME;

const shareUrl = `https://${account}.file.core.windows.net/${sas}`;
const serviceClient = new ShareServiceClient(shareUrl);

// Generates the download URL for the external application installer using Azure credentials
export function generateUrl() {
  const fileClient = serviceClient.getShareClient(shareName).rootDirectoryClient.getFileClient(filename);

  // console.log("----> url: "+fileClient.url)

  return fileClient.url;
}

// Check if a certain string is JSON,
// then return that string as JSON, else return null
export const checkJson = txt => {
  let json = null;
  try {
    json = JSON.parse(txt);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`error: ${err}`);
  }
  return json;
};

export const zonedTimeToUtcString = date => {
  if (!date) return null;
  const dateObj = new Date(date);
  return format(dateObj, "yyyy-MM-dd'T'00:00:00.000'Z'");
};

export const utcToZonedTime = date => {
  if (!date) return null;
  const dateObj = new Date(date);
  return addMinutes(dateObj, dateObj.getTimezoneOffset());
};

export const getUtcDate = date => {
  if (!date) return null;
  return parseISO(new Date(date).toISOString());
};

// Function to convert UTC time to local time in browser
export const getLocalDateFromUtc = date => {
  if (!date) return null;
  const convertdLocalTime = new Date(`${date.toString()}Z`);
  return convertdLocalTime;
};

export const unwrap = (obj, valueTransformer) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (typeof value === 'object' && value !== null && !(value instanceof Date)) {
      return { ...acc, ...unwrap(value, valueTransformer) };
    }
    if (valueTransformer[key]) {
      return { ...acc, ...valueTransformer[key](value) };
    }
    if (value instanceof Date) {
      return { ...acc, [key]: zonedTimeToUtcString(value) };
    }
    return { ...acc, [key]: value };
  }, {});
};

export const spreadValues = (obj, fields = [], acceptedValues = []) => {
  const item = Object.entries(obj).filter(
    ([key, value]) => fields.some(i => i === key) && acceptedValues.some(i => i === value),
  );
  return (item && item[0] && item[0][0]) || null;
};

export const tryExecuteAsync = async (
  setNotificationModalState,
  requestFunc = async () => {},
  message = INTERNAL_ERROR_NOTIFICATION,
) => {
  try {
    const { ErrorMessage, ...rest } = await requestFunc();
    if (ErrorMessage) {
      throw new Error(ErrorMessage);
    }
    return rest;
  } catch (error) {
    setNotificationModalState({
      message: error.message || message,
    });

    throw new Error(error);
  }
};

export const saveAs = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);

  const anchorElem = document.createElement('a');
  anchorElem.style = 'display: none';
  anchorElem.href = url;
  anchorElem.download = fileName;

  document.body.appendChild(anchorElem);
  anchorElem.click();

  document.body.removeChild(anchorElem);

  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 1000);
};

export const base64ToArrayBuffer = base64 => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};
