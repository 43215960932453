import { customFieldsMap, sectionCCustomFieldMap } from 'helpers/constants';

export const formatForm = (e4473, mapObj = customFieldsMap) =>
  Object.keys(mapObj).reduce(
    (acc, item) => {
      if (acc[item]) {
        const { [item]: oldValue, ...rest } = acc;
        const newValue = mapObj[item](acc);
        return { ...rest, ...newValue };
      }
      return acc;
    },
    { ...e4473 },
  );

export const sectionCFormatForm = e4473 => {
  const formattedForm = formatForm(e4473, sectionCCustomFieldMap);
  return { ...formattedForm, ...sectionCCustomFieldMap.C27C(e4473) };
};
