import React from 'react';
import { render } from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';

import './i18n';
import store from 'redux/reducers';

import GlobalStyle from 'components/GlobalStyle';

import AppRouter from './router';

const App = () => {
  return (
    <>
      <GlobalStyle />
      <ReduxProvider store={store}>
        <AppRouter />
      </ReduxProvider>
    </>
  );
};

render(<App />, document.getElementById('root'));
