import axios from 'axios';

import { ROUTES as R, STORAGE_TOKEN_KEYS as K, HTTP_HEADERS as HH, getApiVersion } from 'helpers/constants';

import { getFromStorage, saveTokens, clearStorage } from 'services/storage';

export const HTTP_HEADERS = {
  DEVICE_ID: 'X-Device-ID',
};

const baseURL = process.env.REACT_APP_BASE_API;

const http = axios.create({
  baseURL,
});

export const refreshAuthTokenIfNeeded = async () => {
  const authTokenExpirationDate = getFromStorage(K.AUTH_TOKEN_EXPIRATION);
  if (authTokenExpirationDate >= Date.now()) {
    return true;
  }

  const { username } = getFromStorage(K.USER);
  const oldAuthToken = getFromStorage(K.AUTH_TOKEN);
  const oldRefreshToken = getFromStorage(K.REFRESH_TOKEN);
  const oldRefreshExpiresUtc = getFromStorage(K.REFRESH_TOKEN_EXPIRATION);
  try {
    const {
      data: { AuthenticationToken, User, RefreshToken, RefreshExpiresUtc },
    } = await http.post(
      '/.auth/RefreshToken',
      {
        username,
        authenticationToken: oldAuthToken,
        refreshToken: oldRefreshToken,
        refreshExpiresUtc: oldRefreshExpiresUtc,
      },
      { headers: { checkAuthorization: false } },
    );
    saveTokens(AuthenticationToken, RefreshToken, RefreshExpiresUtc, User);
    http.defaults.headers.common.Authorization = `Bearer ${AuthenticationToken}`;
    return true;
  } catch (error) {
    if (error.response.status === 401) {
      clearStorage();
      return false;
    }
  }
  return false;
};

// const apiVersion = process.env.REACT_APP_API_VERSION;

const beforeRequest = async ({ headers: { checkAuthorization = true, ...restHeaders }, ...rest }) => {
  const apiVersion = getApiVersion();
  // eslint-disable-next-line no-console
  // console.log(apiVersion);
  let baseHeaders = {};
  if (apiVersion) {
    baseHeaders = {
      'x-api-version': apiVersion,
    };
  }

  const config = { ...rest, headers: { ...rest.headers, ...restHeaders, ...baseHeaders } };

  if (checkAuthorization) {
    const isTokenFresh = await refreshAuthTokenIfNeeded();
    if (!isTokenFresh) {
      window.location = R.LOGIN_ROUTE;
    }

    const authToken = getFromStorage(K.AUTH_TOKEN);
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${authToken}`,
    };
  }

  const deviceId = getFromStorage(K.DEVICE_ID);
  if (deviceId) {
    config.headers = { ...config.headers, [HH.DEVICE_ID]: deviceId };
  }

  return config;
};

http.interceptors.request.use(beforeRequest);

export { http };
