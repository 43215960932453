import { Y, N, U } from 'helpers/constants';

export const concatStrings = (separator = ',', ...params) => {
  const data = [...params];
  return data.filter(val => val).join(separator);
};

export const formatBool = (key, value, splitted = true) => {
  const result = value === Y;

  if (!splitted) {
    return { [key]: result };
  }

  return {
    [`${key}_${N}`]: value === N,
    [`${key}_${Y}`]: value === Y,
    [`${key}_${U}`]: value === U,
  };
};

export const separators = {
  comma: ', ',
  dot: '. ',
};
